<template>
  <v-container class="ma-0 pa-0">
    <poll-category :categoryID="categoryID"></poll-category>
  </v-container>
</template>

<script>
import PollCategory from "@/components/poll/PollCategory.vue";

export default {
  components: {
    PollCategory
  },

  computed: {
    categoryID() { return parseInt(this.$route.params.categoryID) },
  }
}
</script>